import React, { useContext, useState, useRef, useEffect } from 'react';
import { styled } from 'linaria/react';
import { PagePadding, Inner } from '../../MaxWidth';
import TopBarUsp from './TopBarUsp';
import { Above } from '@jetshop/ui/Breakpoints';
import { theme } from '../../../Theming/Theming';
import { Notifications } from '../../Notifications';
import TopBarLinks from './TopBarLinks';
import { Link } from 'react-router-dom';
import ChannelContext from '@jetshop/core/components/ChannelContext';

const TopBarWrapper = styled('div')`
  height: ${theme.topBar.height.lg + 'px'};
  transition: height 0.4s ease;
  background: ${theme.topBar.background};
  ${theme.below.md} {
    height: ${theme.topBar.height.md + 'px'};
  }
  &[data-scrolling='true'] {
    height: ${theme.topBar.height.scroll + 'px'};
    overflow: hidden;
  }
  .top-nav-list {
    height: ${theme.topBar.height.lg + 'px'};
    .channel-selector-button {
      color: ${theme.topBar.color};
    }
  }
  .custom-testfreaks-badge {
    display: none;
    position: relative;
    left: 0;
    top: 3px;
    pointer-events: none;
    &.show {
      display: block;
    }
    ${theme.below.lg} {
      position: relative;
      text-align: center;
    }
    .testfreaks .tf-count {
      display: none !important;
    }
    .title-after-score {
      color: white !important;
      margin-right: 8px !important;
    }
  }
`;

const ContentWrapper = styled('div')`
  > div > ul {
    display: flex;
    justify-content: center;
    height: ${theme.topBar.height.lg + 'px'};
    ${theme.below.md} {
      height: ${theme.topBar.height.md + 'px'};
    }
    &:not(.top-nav-list) {
      > li {
        padding: 0 0.666rem;
      }
    }
    > li,
    > li > a,
    > li > button,
    > li:before {
      color: ${theme.topBar.color};
      font-size: ${theme.topBar.fontSize};
      font-weight: ${theme.topBar.fontWeight};
    }
    > li {
      display: flex;
      align-items: center;
      svg {
        color: ${theme.topBar.color};
      }
    }
  }
  > a {
    
    position: absolute;
    display: flex;
    align-items: center;
    padding-top: 6px;
    ${theme.below.lg} {
     position: relative; 
     justify-content: center;
    }
    small {
      color: white;
      font-size: 14px;
      &:last-child {
        padding-left: 6px;
      }
    }
  }
`;

const TopBar = ({ scrolling }) => {
  const { selectedChannel } = useContext(ChannelContext);

  const divRef = useRef(null);
  const [loadTestFreaks, setLoadTestfreaks] = useState(true);
  const [score, setScore] = useState(null);
  const [reviews, setReviews] = useState(null);

  useEffect(() => {
    if (!loadTestFreaks) return;
  
    const interval = window.setInterval(() => {
      if (divRef.current) {
        const scoreDiv = divRef.current.querySelector('.testfreaks');
        const reviews = divRef.current.querySelector('.tf-count');
  
        if (scoreDiv && reviews) {
          const score = scoreDiv.getAttribute('title');
          setReviews(reviews.textContent);
          setScore(score);
          setLoadTestfreaks(false);
          clearInterval(interval); 
        }
      }
    }, 100);
  
    return () => clearInterval(interval);
  }, [loadTestFreaks]);
  
  

  const showTestFreaksReviewBadge = selectedChannel?.id && [1, 2, 3].includes(selectedChannel.id);

  return (
    <TopBarWrapper data-scrolling={scrolling}>
      <PagePadding>
        <Inner>
          <Above breakpoint="lg">
            {(matches) => (
              <ContentWrapper>
                <>
                  {showTestFreaksReviewBadge && (
                    <Link to="/reviews">
                      
                      <div
                        ref={divRef}
                        className={`custom-testfreaks-badge ${
                          loadTestFreaks ? '' : 'show'
                        }`}
                        id="testfreaks-all-reviews-badge"
                      ></div>
                      <small>{score}</small> <small>{reviews}</small>
                    </Link>
                  )}
                </>
                 <TopBarUsp name="TopBarUsp" />
                {matches && <TopBarLinks />}
              </ContentWrapper>
            )}
          </Above>
        </Inner>
      </PagePadding>
      <Notifications />
    </TopBarWrapper>
  );
};


export default TopBar;
