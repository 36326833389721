import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../../Theming/Theming';
import CloseButton from '../../../ui/CloseButton';
import MobileCategories from './MobileCategories';
import UIDrawer from '@jetshop/ui/Modal/Drawer/Drawer';
import DrawerHeader from '../../../ui/DrawerHeader';
import Logo from '../Logo';
import { Link } from 'react-router-dom';
import SiteContentContext from '../../../SiteContent/SiteContentContext';

const StyledCloseButton = styled(CloseButton)`
  padding: 10px;
  margin: -10px;
`;

const Scroll = styled('div')`
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  flex: 1 1 auto;
  padding-bottom: 300px;
`;

const MobileNavWrapper = styled('div')`
  height: 100%;
  .custom-testfreaks-badge {
    padding-top: 12px;
    padding-left: 42px;

    .title-after-score {
      margin-right: 8px !important;
    }

  }
`;

const StyledDrawerHeader = styled(DrawerHeader)`
  background: ${theme.colors.white};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${theme.mobileHeader.nav.padding};
  margin: 1rem 0;
  border: none;
`;

const LogoWrapper = styled('div')`
  padding: 10px 0;
  max-width: 100%;
  width: 100px;
  position: relative;
  left: -5px;
`;

const NavLists = styled('div')`
  padding: 0 ${theme.mobileHeader.nav.padding};
  > * {
    margin-top: 3rem;
  }
  ul {
    li {
      display: block;
      line-height: 42px;
      text-decoration: none;
      border-bottom: 1px solid ${theme.colors.border};
    }
  }
`;

const MobileNav = ({
  categories,
  categoryTreeRoots,
  hideTarget,
  isOpen,
  right,
  ...rest
}) => {
  const { mobilePageList } = useContext(SiteContentContext);

  return (
    <UIDrawer isOpen={isOpen} right={right} size={360}>
      <MobileNavWrapper>
        <StyledDrawerHeader>
          <LogoWrapper>
            <Link to="/" onClick={hideTarget}>
              <Logo mobile={true} />
            </Link>
          </LogoWrapper>
          <StyledCloseButton onClick={hideTarget} />
        </StyledDrawerHeader>
        <Scroll>
          <MobileCategories
            hideTarget={hideTarget}
            categories={categoryTreeRoots}
            categoryTreeRoots={categoryTreeRoots}
            isOpen={isOpen}
          />
          <NavLists onClick={hideTarget}>{mobilePageList}</NavLists>
         
        </Scroll>
      </MobileNavWrapper>
    </UIDrawer>
  );
};
export default MobileNav;
