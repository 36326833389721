import React, { useEffect } from 'react';
import MaxWidth from '../Layout/MaxWidth';
import { styled } from 'linaria/react';
import { getFontSize } from '../Theming/helpers';
import { theme } from '../Theming/Theming';
import t from '@jetshop/intl';


const CategoryName = styled('h1')`
  text-align: center;
  margin-bottom: 4rem;
  ${getFontSize(theme.typography.header.fontSize[3])};
  ${theme.above.md} {
    ${getFontSize(theme.typography.header.fontSize[2])};
  }
  ${theme.above.lg} {
    ${getFontSize(theme.typography.header.fontSize[1])};
  }
`;

const AllReviewsWrapper = styled('div') `

  .testfreaks-reviews {

  .tf-header {
    widht: 100% !important;
    border: none;
    border-radius: 3px !important;

    .tf-score {
      margin: 0 !important;
      float: none !important;
    }
    .tf-distributions {
      display: flex !important;
      flex-direction: column;
      justify-content: center;
      width: 100% !important;
      padding-top: 1rem !important;
      margin: 0 !important;
      ${theme.above.md} {
        flex-direction: row;
        > div {
          padding: 0 24px !important;
        }
      }
      ${theme.above.lg} {
        padding-top: 0 !important;
      }
      .tf-dist-all {
        max-width: 100% !important;
        ${theme.below.sm} {
          flex-direction: column;
        }
        ${theme.above.sm} {
          display: flex !important;
          flex-direction: column;
          align-items: center !important;
        }
        ${theme.above.md} {
          display: none !important;
          flex-direction: row;
          background: green !important; 
        }         
      }
      ${theme.above.lg} {
        width: auto !important;
      }
    }

    ${theme.above.lg} {
      width: 35% !important; 
      border: 1px solid #b3b3b3 !important; 
      padding: 32px 32px 32px 16px !important;   
      float: right !important;
      border-radius: 3px !important;
      position: relative !important;
      display: flex !important;
      justify-content: space-around;;
    }     

  }
  .testfreaks {
    .tabs,
    .tf-tabs-panels {
      width: 100% !important;
      ${theme.above.lg} {
        width: 55% !important;
      }
      
    }
  }
}

`;

const AllReviewsPage = () => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (!window.testFreaks) window.testFreaks = [];
      window.testFreaks.push(['load', ['all-reviews']]);
    }
  });
  return (
    <MaxWidth>
      <CategoryName data-testid="page-header" className="category-name">
      {t('Review')}
      </CategoryName>
      <AllReviewsWrapper>
        <div className="all-reviews-wrapper" id="testfreaks-all-reviews"></div>
      </AllReviewsWrapper>
    </MaxWidth>
  );
};

export default AllReviewsPage;
