import ChannelContext from '@jetshop/core/components/ChannelContext';
import DynamicRoute from '@jetshop/core/components/DynamicRoute';
import PaginationProvider from '@jetshop/core/components/Pagination/PaginationProvider';
import { ProductListProvider } from '@jetshop/core/hooks/ProductList/ProductListContext';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import GenericError from '@jetshop/ui/ErrorBoundary/Generic';
import LoadingBar from '@jetshop/ui/Loading/LoadingBar';
import ModalProvider from '@jetshop/ui/Modal/ModalProvider';
import ModalRoot from '@jetshop/ui/Modal/ModalRoot';
import ScrollRestorationHandler from '@jetshop/ui/ScrollRestorationHandler';
import loadable from '@loadable/component';
import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Switch } from 'react-router-dom';
import { config, getChannel } from '../shop.config.custom';
import ForgotPassword from './Auth/ForgotPassword.loadable';
import LogInPage from './Auth/LogInPage.loadable';
import ResetPassword from './Auth/ResetPassword.loadable';
import SignUpPage from './Auth/Signup/SignUpPage.loadable';
import BlogArticle from './Blog/BlogArticle.loadable';
import BlogPage from './Blog/BlogPage.loadable';
import CategoryPage from './CategoryPage/CategoryPage.loadable';
import CategoryPreviewQuery from './CategoryPreviewQuery.gql';
import ContentPage from './ContentPage/ContentPage.loadable';
import KlaviyoProvider from './Klaviyo';
import Container from './Layout/Container';
import Content from './Layout/Content';
import Footer from './Layout/Footer/Footer';
import Header from './Layout/Header/Header';
import LoadingPage from './LoadingPage';
import MyPages from './MyPages/MyPages.loadable';
import NotFoundPage from './NotFoundPage.loadable';
import PagePreviewQuery from './PagePreviewQuery.gql';
import { Favourites } from './ProductList/Favourites';
import { productListQueries } from './ProductList/productListQueries';
import ProductPage from './ProductPage/ProductPage.loadable';
import ProductPreviewQuery from './ProductPreviewQuery.gql';
import routeQuery from './RouteQuery.gql';
import SearchPage from './SearchPage/SearchPage.loadable';
import SiteContentContext from './SiteContent/SiteContentContext';
import SiteContent from './SiteContent/SiteContentProvider';
import StartPage from './StartPage/StartPage.loadable';
import { theme } from './Theming/Theming';
import './Theming/globalStyles';
import CookieConsent from './Util/CookieConsent';
import TypekitFont from './Util/Fonts/TypekitFont';
import useScript from './ui/hooks/useScript';
import AllReviewsPage from './AllReviewsPage/AllReviewsPage';

const LogOutPage = loadable(
  () => import('@jetshop/core/components/AuthContext/LogOut'),
  {
    fallback: <LoadingPage />,
  }
);
const PreviewRoute = loadable(
  () => import('@jetshop/core/components/DynamicRoute/PreviewRoute'),
  {
    fallback: <LoadingPage />,
  }
);

const Routes = () => {
  const { routes } = useShopConfig();
  const { blogCategory } = useContext(SiteContentContext);
  const blogObject = blogCategory
    ? {
        path: blogCategory?.primaryRoute?.path,
        id: blogCategory?.id,
        mainLevel: 1,
        menu: true,
      }
    : {
        path: 'undefined-blog',
        id: 9999,
        mainLevel: 1,
        menu: true,
      };

  return (
    <Switch>
      <Route exact path={`${blogObject.path}/:cat/:item`}>
        <BlogArticle configObject={blogObject} />
      </Route>
      <Route exact path={`${blogObject.path}/:cat`}>
        <BlogPage configObject={blogObject} />
      </Route>
      <Route exact path={blogObject.path}>
        <BlogPage configObject={blogObject} />
      </Route>
      <Route exact path="/">
        <StartPage />
      </Route>
      <Route path={config.routes.favourites.path}>
        <Favourites />
      </Route>
      <Route path={routes.search.path}>
        <SearchPage />
      </Route>
      <Route path={routes.signup.path}>
        <SignUpPage />
      </Route>
      <Route path={routes.login.path}>
        <LogInPage />
      </Route>
      <Route path={routes.logout.path}>
        <LogOutPage />
      </Route>
      <Route path="/reviews">
        <AllReviewsPage />
      </Route>
      <Route path={routes.myPages.path}>
        <MyPages />
      </Route>
      <Route exact path={routes.forgotPassword.path}>
        <ForgotPassword />
      </Route>
      <Route path={`${routes.resetPassword.path}/:token`}>
        <ResetPassword />
      </Route>
      <Route
        path="/preview"
        render={(props) => (
          <PreviewRoute
            productQuery={ProductPreviewQuery}
            categoryQuery={CategoryPreviewQuery}
            pageQuery={PagePreviewQuery}
            productPage={ProductPage}
            categoryPage={CategoryPage}
            contentPage={ContentPage}
            StartPage={StartPage}
            {...props}
          />
        )}
      />
      <DynamicRoute
        routeQuery={routeQuery}
        productPage={ProductPage}
        categoryPage={CategoryPage}
        contentPage={ContentPage}
        notFoundPage={NotFoundPage}
        LoadingPage={LoadingPage}
      />
    </Switch>
  );
};

function Shop() {
  const { selectedChannel } = useContext(ChannelContext);
  const channel = getChannel(selectedChannel.name);
  const isDev = false;

  //TESTFREAKS REVIEWS
  const testfreakUrl = channel.domain
  ? `https://js.testfreaks.com/onpage/${channel.domain}${
      isDev ? '-staging' : ''
    }/head.js`
  : false;

  useScript(
    testfreakUrl
  );
 
    useEffect(() => {
      if (typeof window !== 'undefined') {
        if (!window.testFreaks) window.testFreaks = [];
        window.testFreaks.push(['reset']);
        window.testFreaks.push(['load', ['all-reviews', 'all-reviews-badge']]);
      }
    });

  return (
    <GenericError>
      <ModalProvider>
        <SiteContent>
          <Container>
            <LoadingBar />
            <TypekitFont
              primaryFont={theme.font.primary}
              secondaryFonts={[theme.font.secondary]}
              id="quh5hrp"
            />
            <Helmet titleTemplate={`%s`} defaultTitle={channel.storeName}>
              <link
                rel="shortcut icon"
                type="image/png"
                href={channel.favicon}
                sizes="32x32"
              />
            </Helmet>
            <KlaviyoProvider selectedChannel={selectedChannel} />
            <ProductListProvider queries={productListQueries}>
              <Header />
              <Content>
                <PaginationProvider defaultProductsPerPage={36}>
                  <Routes />
                </PaginationProvider>
              </Content>
              <Footer />
            </ProductListProvider>
            <ModalRoot />
            <ScrollRestorationHandler
              ignoreForRouteTypes={[
                'sortOrderChange',
                'filterChange',
                'paginationChange',
              ]}
            />
            <CookieConsent />
          </Container>
        </SiteContent>
      </ModalProvider>
    </GenericError>
  );
}

export default Shop;
